export default {
    "message": {
        "hello": "hello world"
    },
    "Phone": '0544 311 384',
    "whPhone": '0550 800 510',
    "SaidAbout":"Said about Logexa",
    "English": "العربية",
    "ShowMore": "Show More",
    weWillContactYouSoon:"your message has been successfully recivied, we will respond to you shortly",
    sentSuccessfully: "Sent Successfully",
    subscribedSuccessfully: "Subscribed Successfully",
    "More": "More",
    yourName:"Your Name",
    footerEmailPlaceholder:"Your Email",
    "cograturationForSP":"🎉 Congrats on becoming a partner! 🎉 Its that simple",
    seo: {
        title: "Logexa",
        description: "With Logexa, the solution for warehouse storage in the Kingdom, you can now store your products securely through our network of strategically warehouses in Riyadh, Jeddah, and other cities. Enjoy the flexibility of monthly rental contracts and manage your warehouse with our cloud-based storage service. ",
        keywords: "chemical warehousing, logistics services, logexa, warehouse logistics, food warehousing, logexa, logistics saudi arabia, pallet storage	, ambient storage	, Warehouse, warehouse logistics, warehousing and logistics, warehousing space, chilled warehouse, business warehousing, ksa logistics, logistics and warehousing company, warehouse ksa, frozen warehousing, 3pl warehouse, warehouse rental, find warehouse, business warehouses, business warehouse storage, space warehouse, warehouse find, warehouse rental business, cheap warehouse for lease, rent a warehouse space near me, rental warehouse property management, warehouse rental spaces near me, lease of warehouse, space for warehouse, warehouse units near me, space warehouse for rent #Logexa #OnDemandWarehouse #warehousing #warehouse #logistics #SaudiArabia #jeddah #dammam #riyadh #logisticsservices #storage",

        main_page: {
            title: "Best Storage Solutions in Riyadh, Saudi Arabia ",
            description: "With Logexa, the solution for warehouse storage in the Kingdom, you can now store your products securely through our network of strategically warehouses in Riyadh, Jeddah, and other cities. Enjoy the flexibility of monthly rental contracts and manage your warehouse with our cloud-based storage service. ",
            keywords: "",
        },
        about_us: {
            title: "About Logexa",
            description: "Logexa is the first on-demand warehouse in Saudi Arabia. We offer storage solutions in several locations in Jeddah, Riyadh & Dammam. We offer pallet storage, SQM storage, and transportation services. Manage your inventory online through cloud warehouse.",
            keywords: "",
        },
        contact_us: {
            title: "Contact Us",
            description: "You can reach us any time through our platform or by email at info@logexa.com or by calling us at +966 544 311 384",
            keywords: "",
        },
        transportation_page: {
            title: "Transportation Services",
            description: "A smooth and reliable shipping experience with the best shipping services in the Kingdom of Saudi Arabia. We provide comprehensive solutions for transporting your goods safely and efficiently. Discover our options now!",
            keywords: "",
        },
        pallet_storage_page: {
            title: "Pallet Storage",
            description: "Discover efficient and space-saving pallet storage solutions with Logexa. We offer various storage spaces and sturdy containers that cater to all your needs.",
            keywords: "",
        },
        sqm_storage_page: {
            title: "Platform",
            description: "You can manage your inventory through our platform and request many services such as: shipping your products to the warehouse, picking up your goods, and much more.",
            keywords: "",
        },
        howitworks_page: {
            title: "How it works",
            description: "With Logexa logistics solutions platform, you can now reserve your storage space in three simple steps. Enjoy the convenience of managing your inventory through our cloud-based warehouse service.",
            keywords: "",
        },
        blog_page: {
            title: "Logexa Blog",
            description: "Explore Logexa's blog to find valuable information about the latest developments and strategies in the logistics field. Discover various tips and ideas for effectively managing inventory. Read more now.",
            keywords: "",
        },
        news_page: {
            title: "Logexa News",
            description: "Stay up to date with Logexa’s recent activites",
            keywords: "",
        },

    },
    super_nav: {
        account: "My Account",
        login_signup: "Login/Signup",
        dashboard: "Dashboard",
        my_profile: "My Profile",
        quotations: "Back to Quotation",
        logout: "Logout",
    },
    contact_us: {
        hero: {
            heading: "CUSTOMER SERVICE",
        },
        form: {
            title: "Send us a message",

            name: "Your Name",
            name_placeholder: "Your full name here",
            email: "Email Address",
            email_placeholder: "Your Email Address here",
            message: "Your Message",
            message_placeholder: "Please enter your message in this text box (minimum 20 characters)...",
        },
    },
    careers: {
        hero: {
            title: "Be part of building something that people love.",
            see_openings_cta: "See Openings",
        },
        why_to_join: {
            title: "Why to join us?",
            description: "we are nothing but a family.",
            reason1: {
                heading: "Flexible work schedule",
                content:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing",
            },
            reason2: {
                heading: "Social Insurance",
                content:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing",
            },
            reason3: {
                heading: "Medical Insurance",
                content:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing",
            },
        },
        job_openings: {
            title: "Job Openings",
            description: "We are expanding, be part of our coming success story.",
            cities: {
                jeddah: "Jeddah",
                riyadh: "Riyadh",
            },
            departments: {
                sales: "Sales",
                finance: "Finance",
                quality_assurance: "Quality Assurance",
                data: "Data",
                marketing: "Marketing",
            },
        },
        career_list: {
            job_location: {
                on_site: "On Site",
                remote: "Remote",
            },
        },
    },
    footer_v2: {
        general: "General",
        for_you: "For You",
        other: "Other",
        home: "Home",
        about_us: "About us",
        how_it_works: "How it works",
        blog: "Blog",
        pallet_storage: "Pallet Storage",
        transportation: "Transportation",
        sqm_storage: "SQM Storage",
        service_provider: "Service Provider",
        contact_us: "Contact us",
        privacy_policy: "Privacy Policy",
        terms_of_use: "Terms of Use",
        careers: "Careers",
        faq: "FAQ",
        footer_description: "Sign up to our newsletter and get 10% off your first quotation Get alerts on new offers, locations and more! ",
    },
    "nav": {
        "home": "home",
        "about": "about",
        "contact": "contact",
        "HowWeWork": "How We Work",
        "OurServices": "Our Services",
        "AboutUs": "About Us",
        "OurResources": "Our Resources",
        "Login": "Login"
    },
    "footer": {
        "General": "General",
        "ForYou": "For You",
        "Others": "Others",

        "Home": "Home",
        "HowWeWork": "How We Work",
        "AboutLogexa": "About Logexa",
        "Blog": "Blog",
        "StoreByPallet": "Store By Pallet",
        "StoreByCarton": "Store By Carton",
        "Delivery": "Delivery",
        "ServiceProvider": "Service Provider",
        "ContactUs": "Contact Us",
        "PrivicyPolicy": "Privicy Policy",
        "TermsAndConditions": "Terms And Conditions",
        "Careers": "Careers",
        "FAQ": "FAQ",
        "disc_header": "Get 10% discount",
        "disc_desc": "Subscribe to our newsletter and get 10% off your first quote.",
        "disc_desc2": "Get alerts about new offers, locations, and more!"
    },
    solution: {
        heading: 'The Best Storage Solutions In The Kingdom Of Saudi Arabia',
        desc: "With Logexa, the solution for warehouse storage in the Kingdom, you can store your products securely through our network of strategic warehouses in Riyadh, Jeddah, Dammam.Enjoy the flexibility of monthly contracts and manage your stock with our inventory management system.Through our platform, you can create shipments to and from the warehouse",
        Question1: "Warehouses for Rent in Riyadh and Jeddah, Suitable for Various Products",
        answer1: "Spaces in Warehouses for Rent in Riyadh, Jeddah and Dammam, Suitable for Various Products At Logexa, we aim to assist businesses in Saudi Arabia to grow by providing warehouses with various spaces and competitive rental prices.Our monthly contracts cater to the diverse and ever- changing needs of our customers, offering the option to manage the warehouse through our inventory management platform.",
        Question1_2: "The ability to manage inventory through our platform",
        answer1_2: "With Logexa, small warehouses in Riyadh, Jeddah, Dammam, and other cities within the Kingdom of Saudi Arabia can expand without worrying about their inventory size.To benefit our customers, we provide flexible storage services with monthly contracts, eliminating the need for long - term commitments or large storage spaces.Simply inform us about your storage requirement, and we'll provide suitable warehouses without a required minimum number of pallets or square meters.",
        Question2: "The Most Suitable Warehouses for Your Products in KSA",
        answer2: "The high temperatures in Saudi Arabia, particularly in Jeddah and Riyadh, can damage your products. To ensure the safety of your goods, we offer warehouses for monthly rent with various temperature settings to accommodate the specific materials you wish to store."
    },
    "Hero": {
        "smallHead": "There's always space for you",
        "Title1": "Best storage",
        "ForStoring": "solution",
        "Title2": "Inside the Kingdom of Saudi Arabia",
        "askQoutation": "Request a Quotation",
        "SelectTemperature": "Choose temperature",
        "SelectProductType": "Choose product type",
        "SelectStorageType": "Choose storage type",
        "StoredSku": "Products Stored",
        "ClientTrustsUs": "Served Clients",
        "ReadyWarehouse": "Warehouse Network",
        "BrandsTrustUs": "Many brands that trust in",
        "Logexa": "Logexa",
        "AskRFQ": "Request a Quotation",
        "whyLogexa_desc": "We provide our customers with what they need when they need it. Whether they are looking to commit to a long-term contract or store for a month, we've got it. We provide our customers with what they need when they need it. Whether they are looking to commit to a long-term contract or store for a month, we've got it.",
        "YourWarehouseReady_desc": "Through the first on-demand logistics platform in Saudi Arabia offering flexible logistics solutions, you can now manage your inventory, including storage and transportation, without the need to speak to your account manager.",
        "YourWarehouseReady": "Your warehouse is ready and your space is waiting for you!",
        "WhBenefit": "Do you want to benefit from your warehouse?",
        "JoinUsAsSP": "Join us as a service provider",
        "WhBenefit_desc": "Your success is our success, that's why we want to work with you! We help warehouses use their unused capacity to increase their profit without the hassle of dealing with multiple small customers.",
        "CompleteTheQuotationRequest": "Complete Request Quotation",
        "desc_1": "Through Logexa, you can store your products in warehouses in Riyadh, Jeddah, Dammam, and other cities safely and with monthly contracts.", //You can also manage your inventory through our platform and create shipments to and from the warehouse.
        "desc_2": "You can also manage your inventory through our platform and create shipments to and from the warehouse."
    },
    "product": {
        "title": "Product Variety",
        "desc": "At Logexa, we pride ourselves on accommodating and securely storing varieties of products according to their specific storage needs.We always provide suitable temperature and storage conditions that will preserve your products’ quality and freshness"
    },
    "contact_us_card": {
        "contact_us": "Connect with LOGEXA experts!",
        "lets_have_a_conversation": "Let's have a conversation",
        "contact_us_description": "We'd love to show you around our platform and answer all your questions. Contact us now, we will reply asap!"
    },
    "faq": {
        "heading": "FAQ",
        "description": "Every thing you need to know about Logexas’ services and how it works. Can’t find the answer? please chat with our friendly team. ",
        "faq_list": {
            "question1": {
                "q": "Why Logexa?",
                "a": "Storage in the warehouse starting from one pallet\n Without long-term contracts, one month as a minimum\n Flexibility \n An electronic platform for managing your inventory \n All types of dry, moderate- temperature, refrigerated, and frozen warehouses in Riyadh, Jeddah, and other cities"
            },
            "question2": {
                "q": "What is on demand warehousing?",
                "a": "In isolation, business owners like you rarely have the resources to find workspaces designed to meet their unique needs. But when these business owners come together in one central location like Logexa, they can access both the spaces and services they need individually and benefit from shared knowledge and a sense of community.This is shared storage. \n LOGEXA helps business owners find warehouses in Riyadh, Jeddah, and other cities for temporary storage or for a long time to save space, needs, and benefit from services"
            },
            "question3": {
                "q": "How to request quotation?",
                "a": "You can request a quote for your product to be stored inside the warehouse easily through the Logexa platform"
            },
            "question4": {
                "q": "How can I request a quotation? What are the available payment options?",
                "a": "We have multiple payment options for your ease Bank transfer / Mada / Apple Pay."
            },
            "question5": {
                "q": "Does Logexa own the warehouses?",
                "a": "No, but we have a large network of service providers and serve our customers by helping the warehouse to take advantage of the available spaces."
            },
            "question6": {
                "q": "Where is Logexa’s head office located?",
                "a": "Jeddah, Saudi Arabia"
            },
            "question7": {
                "q": "What are the working hours of logexa?",
                "a": "from 8 am to 4 pm."
            },
           
            
            // "question10": {
            //     "q": "How can I join Logexa’s team?",
            //     "a": "you can follow careers page and upload your CV"
            // },
            "question8": {
                "q": "What sectors does Logexa serve?",
                "a": "We serve several sectors, including retail, consumer goods, food and beverage, and the industrial sector."
            },
            "question9": {
                "q": "What kind of products can LOGEXA store?",
                "a": "Foodstuffs, cosmetics, dangerous goods, chemicals, personal products, clothing, etc."
            },
            "question10": {
                "q": "Can you be Logexa’s partner?",
                "a": "Logexa is always open for strategic partnerships."
            },
            "question11": {
                "q": "Are there any promo codes?",
                "a": "Yes, you can follow us in our social media pages."
            },
            "question12": {
                "q": "Who assumes the liability? ",
                "a": "We assume liability for losses or damages that occur within the facility."
            }
        }
    },
    nav_links_v3: {
        about_us: "About us",
        services: "Services",
        how_it_works: "How it works",
        resources: "Resources",
        pallet_storage: "Pallet Storage",
        pallet_storage_description:
            "We provide pallet storage to speed up and improve the efficiency of handling and storing your precious item",
        transportation: "Transportation",
        transportation_description:
            "We will match you with the right transportation service",
        sqm_storage: "SQM Storage",
        sqm_storage_description:
            "We provide the most suitable space to meet your products’ needs whatever they may be.  ",
        blog: "Blog",
        blog_description:
            "Read and learn all about logistics, warehousing, and more! ",
        news: "News",
        news_description: "Stay up to date with Logexa’s recent activites ",
    },
    "storage_options": {
        "title": "Storage Types",
        "AskQoutation": "Request a Quotation",
        "description": "At Logexa, we provide temperature-controlled warehousing solutions for a variety of products. We curate the most suitable environment for your products with a wide range of temperature options available.",
        "option1": {
            "name": "Dry",
            "description": "Our dry storage facilities provide the most suitable environment for products that must be kept away from moisture and direct sunlight."
        },
        "option2": {
            "name": "Ambient",
            "degree": "21-25 degrees celsius",
            "description": "Ambient storage refers to a type of storage system in which products are typically kept at room temperature.A variety of foods, cosmetics, and chemicals are kept within our ambient storage facilities to preserve their quality and maintain their freshness."
        },
        "option3": {
            "name": "Chilled",
            "degree": "5-15 degrees celsius",
            "description": " A low temperature between 5-15 degrees Cْ is maintained for the items, thanks to our chilled storage warehouses where a variety of products can be stored to preserve their qualities."
        },
        "option4": {
            "name": "Frozen",
            "degree": "-18 degrees celsius",
            "description": "If you want to preserve your products’ quality and keep their freshness for long periods of time, then choose our frozen storage facilities where we keep your products stored at - 18 Celsius.This is the perfect storage solution for raw meat, poultry and some pharmaceuticals."
        }
    },
    "our_services": {
        "title": "Our Services",
        "card": {
            "title1": "Pallet Storage",
            "learnMore": "Learn More",
            "desc": "A pallet is a storage unit for goods and materials. Our pallets follow European pallet standards, and the measurements are= 1.0m * 1.2m * 1.6m. If you don’t have pallets, no problem. We provide a pallet building service that includes the wooden pallet, organizing the cartons on the pallet, and shrink- wrapping.",
            "title2": "Platform",
            "desc2": "You can manage your inventory through our platform and request many services such as: shipping your products to the warehouse, picking up your goods, and much more.",
            "title3": "Transportation",
            "desc3": "If you require transportation of your products from and to the warehouse, then you can simply do it by requesting it on the platform and we will match you with the right transportation service. "
        }
    },
    "common": {
        "logexa": "Logexa",
        "title": "Storage Warehouse Company Saudi Arabia | Logexa",
        "rfq": "REQUEST QUOTATION",
        "requestQuotation": "Request quotation",
        "slogan": "Find the most flexible storage solution for your products in KSA",

        "show_article": "Show the article",
        "email_address": "Email address",
        "name": "Name",
        "subscribe": "Subscribe",

        "locations": "Locations",
        "departments": "Departments",
        "team": "Team",
        "expand": "Expand",
        "collapse": "Collapse",
        "call": "Call Us at",
        "email": "Email Us at",
        "location": "Our Location at",
        "our_locations": "Our Locations",
        "jeddah": "Jeddah, KSA",
        "submit": "Submit Request",
        "storage": "Storage",
        "dry": "Dry",
        "ambient": "Ambient",
        "chilled": "Chilled",
        "frozen": "Frozen",
        "contact_us": "Contact Us",
        "copy": "Copy article link",
        "login": "Login",
        "signup": "Signup",
        "or": "or",
        "logout": "Logout",
        "close": "Close",
        "celsius": "celsius",
        "delete": "Delete",
        "workHours": "8am ~ 4pm",
        "workDays": "Saturday ~ Thursday",
        "lets_have_a_conversation": "Let's have a conversation",
        "store_with_us": "Store with us",
        "get_off": "Get 20% off",
        "your_email_address_here": "Your email address here",
        "follow_us": "Follow us",
        "more_articles": "More articles",
        "share_the_article": "Share the article",
        "show_more": "Show more",
        "more_about_us": "More about us",
        "join": "Join as a service provider",
        "learnMore": "Learn more",
        "service_provider": "Service Provider?",
        "cancel": "Cancel"
    },
    "landingPage": {
        "BlogSec": {
            "title": "Stay Updated",
            "desc": "We share thoughtful articles"
        }
    },
    "about_us": {
        "stats": {
            "title1": "Client",
            "title2": "Warehouses",
            "title3": "Cities",
            "title4": "Product",
            "desc1": "Successfully used our services",
            "desc2": "have joined our mission",
            "desc3": "We operate in multiple places",
            "desc4": "Stored in our warehouse network"
        },
        "why_logexa": {
            "title": "Why Logexa?",
            "description": "We help Businesses Grow by Facilitating Storage and Logistic Solutions",
            "cardtitle1": "We Are Flexible",
            "cardtitle1_desc": "We provide our clients with what they need when they need it. Whether they are looking to commit to a long-term contract or store for a month, we got it.",
            "cardtitle2": "Always room for more",
            "cardtitle2_desc": "TThere are no constraints to what or how you want to store your products with us. A fleet of containers or a pallet, there’s always space for it.",
            "cardtitle3": "Our Digital Platform",
            "cardtitle3_desc": "Our innovative platform allows users to complete their quotation, pay for it, manage their stock, and request value-added services in the easiest, most secure, and seamless manner, wherever they are, be it at home, the office, or the car!",
            "cardtitle4": "Our Warehouse Network",
            "cardtitle4_desc": "Our services are not limited to specific locations, we are proud to have geographical coverage throughout the kingdom."
        },
        "our_values": {
            "title": "Our value",
            "description": "Unlock on-demand storage freedom for everyone ",
            "Integrity": "Integrity",
            "Reliability": "Reliability",
            "Inclusivity": "Inclusivity",
            "Sustainability": "Sustainability",
            "Sustainability_desc": "We here at logexa value our planet and try to do our part in preserving it by utilizing existing warehouses throughout the kingdom to reduce solid waste and pollution created by new warehouse construction.",
            "Inclusivity_desc": "At logexa, we encourage and support all personnel to express their ideas and themselves freely to host a comfortable and creative working environment for everyone.",
            "Reliability_desc": "We aspire to always have dependable and reliable interactions between our employees, service providers, and clients.",
            "Integrity_desc": "It is important that the company and its employees act ethically and responsibly to establish trustworthy relationships within and outside our company."
        },
        "hero": {
            "title": "Logexa is an on-demand storage platform",
            "desc2": "We are the first Saudi on-demand warehousing platform that provides different storage options within multiple locations.With Logexa’s services, warehouses can increase their utilization rate.On the other hand, businesses can benefit from flexible, fast storage solutions without worrying about scalability issues with their inventory.",
            "description": "We are the first Saudi on-demand warehousing platform that provides different storage options within multiple locations.With Logexa’s services, warehouses can increase their utilization rate.On the other hand, businesses can benefit from flexible, fast storage solutions without worrying about scalability issues with their inventory."
        },

        "purpose": {
            "title": "PURPOSE",
            "description": "Help Businesses Grow by Facilitating Storage and Logistic Solutions"
        },
        "specs": {
            "title": "WHY LOGEXA?",
            "description": "There are several reasons why businesses partner with us. We provide our clients with what they need when they need it. Whether they are looking to commit to a long-term contract or store for a month, we got it! There are no constraints as to what or how you want to store your products with us. A fleet of containers or a pallet, there’s always space for it. Our innovative platform allows users to complete their quotation, pay for it, manage their stock, and request value-added services in the easiest, most secure, and seamless manner, wherever they are, be it at home, the office, or the car! Our services are not limited to specific locations, we are proud to have geographical coverage throughout the kingdom.",
            "title1": "We Are Flexable",
            "title2": "Always room for more",
            "title3": "Our Digital Program",
            "title4": "Our Warehouse Network",
            "desc1": "We provide our clients with what they need when they need it. Whether they are looking to commit to a long-term contract or store for a month, we got it.",
            "desc2": "There are no constraints to what or how you want to store your products with us. A fleet of containers or a pallet, there’s always space for it.",
            "desc3": "Our innovative platform allows users to complete their quotation, pay for it, manage their stock, and request value-added services in the easiest, most secure, and seamless manner, wherever they are, be it at home, the office, or the car!.",
            "desc4": "Our services are not limited to specific locations, we are proud to have geographical coverage throughout the kingdom.",
            "warehouse_network_spec": {
                "h2": "OUR WAREHOUSE NETWORK",
                "description": "Storing your products couldn't be easier.",
                "step1": "Short and long term availability",
                "step2": "Save time and money",
                "step3": "Pay as you go"
            },
            "digital_program_spec": {
                "h2": "OUR DIGITAL PROGRAM",
                "step1": "Innovative technology platform.",
                "step2": "Instant quotations.",
                "step3": "Secure transactions"
            }
        },
        "partners": {
            "title": "Trusted by",
            "description": "Trusted and loved by 280+ companies all around the kingdom."
        },
        "team": {
            "title": "OUR TEAM",
            "description": "Behind every successful company is an exceptional group of people leading the team.<br>Meet the extraordinary team moving our company forward.",
            "names": {
                "husam_sendi": "Husam Sendi",
                "husam_sabano": "Husam Sabano",
                "khalid_nagadi": "Khalid Nagadi",
                "areej_amin": "Areej Amin",
                //"Marah_Jarad": "Marah Jarad",
                "Nouman_Farooq": "Nouman Farooq",
                "Majed_Alqathmi": "Majed Alqathmi",
                "noha_alhasan": "Noha Alhasan",
                "Azharuddin_Mohammed": "Azharuddin Mohammed",
               // "Waleed_Atiah": "Waleed Atiah",
                "Saad_Almuqrin": "Saad Almuqrin",
                "Faisal_Altamehi": "Faisal Altamehi",
               //": "Hisham Alamoudi",
                "Eslam_Dahshan": "Eslam Dahshan",
                //"Ahmed_Qamar": "Ahmed Qamar",
                //"Taif_Abdulbagi": "Taif Abdulbagi",
                "Duaa_Thaiban": "Duaa Thaiban",
                //"Saleh_Bahashwan": "Saleh Bahashwan",

                //"Mohammed_Alamoudi": "Mohammed Alamoudi",
                "Abdullah_Bokhari": "Abdullah Bokhari",
                //"Raneem_Alghamdi": "Raneem Alghamdi",
                //"Duaa_Sulaimani": "Duaa Sulaimani",
                //"Baraah_Halawani": "Baraah Halawani",
                "hasan_mustafa": "Hasan Mustafa",
                "abdurahman_suliman": "Abdurahman Suliman",
                "ahmed_atif": "Ahmed Atif",
                //"hisham_alamoudi": "Hisham Alamoudi",
                "dania_hussien": "Dania Hussien",
                "mohammed_rawas": "Mohammed Rawas",
                "nourhan_tharwat": "Nourhan Tharwat",
                "alaa_abualhasan": "Alaa Abualhasan",
               // "alaa_mansour": "Alaa Mansour",
                //"abdulkareem_alasami": "Abdulkareem Alasami",
                "afnan_alghamdi": "Afnan Alghamdi",
                "aalian_ansari": "Aalian Ansari",
                "abdullah_alsharif": "Abdullah Alsharif",
                "numan_ali": "Numan Ali",
                "muhammed_ibrahim": "Muhammed Ibrahim",
                //"ahmed_qamaralam": "Ahmed Qamaralam",
                "faisal_altamehi": "Faisal Altamehi",
                "yusuf_alhabeeb": "Yusuf Alhabeeb",
                "abdullah_bokhari": "Abdullah Bokhari",
                "wdad_alharbi": "Wdad Alharbi",

                //"Jory_Majdi_Waddu": "Jory Majdi Waddu",
                //"Mohammed_Rawas": "Mohammed Rawas",
               // "Taha_Osama_Aziz": "Taha Osama Aziz",
                "Khalid_Nagadi": "Khalid Nagadi",
                "Faris_Almaharasi": "Faris Almaharasi",
                "Omar_Mohammed": "Omar Mohammed",
                "Hassan_Jamal": "Hassan Jamal"
            },
            "cta_more": "Show More",
            "cta_less": "Show Less"
        },
        "contact_us": {
            "title": "LIFE AT LOGEXA",
            "description": "At Logexa, we value passionate people who deeply respect their craft",
            "cta": "Contact us"
        }
    },
    "services": {
        "product": {
            "title": "Product Variety",
            "desc": "At Logexa, we pride ourselves on accommodating and securely storing varieties of products according to their specific storage needs.We always provide suitable temperature and storage conditions that will preserve your products’ quality and freshness"
        },
        "transportation": {
            "title": "Transportation",
            "description": "If you require transportation of your products from and to the warehouse, then you can simply do it by requesting it on the platform and we will match you with the right transportation service "
        },
        "palletStorage": {
            "title": "Pallet Storage",
            "description": "A pallet is a storage unit for goods and materials.Our pallets follow European pallet standards, and the measurements are= 1.0m * 1.2m * 1.6m.If you don’t have pallets, no problem.We provide a pallet building service that includes the wooden pallet, organizing the cartons on the pallet, and shrink- wrapping."
        },
        "sqm": {
            "title": "SQM Storage",
            "description": "In our square meter storage, we provide the most suitable space to meet your products’ needs whatever they may be.Square meter is the measure of length by width(without height), the square meter fits a lot of products such as furniture, cars, machinery, etc.."
        },
        "descriptiveSection": {
            "title1": "Cost-Effective",
            "desc1": " Providing transportation services can be a cost-effective solution for logistical companies, as they don't have to invest in their own vehicles and drivers. Outsourcing transportation can also help reduce overhead costs, such as maintenance, fuel, and insurance."
        },
        "careSection": {
            "mainTitle": "We care of each detail, that’s why there is always room for more. ",
            "maindesc": "We have high quality facilities ",
            "title1": "Highly qualified staff",
            "desc1": "We work with highly trained staff to increase safety and improve efficiency. Employees follow protocols and guidelines within and outside the company to ensure the highest service quality.",
            "title2": "Safety assurance",
            "desc2": "We make the safety of the operating staff and your products our top priority. Hence, we go the extra mile to ensure all safety guidelines and protocols are being constantly met.",
            "title3": "High level of security ",
            "desc3": "All warehouses contain surveillance cameras and security personnel that work tirelessly to keep your goods secure and ensure that they are in good condition.",
            "title4": "Market proximity",
            "desc4": "Our network of locations is well connected and easily accessible. We provide proper transportation services for our client’s goods if needed.",
            "title5": "Storage space",
            "desc5": "We use different machinery to handle all types of products. We have the right machine to handle large or small loads.",
            "title6": "Proper design",
            "desc6": "We partner with warehouses that are designed to store 4 different temperatures of products and can operate large/heavy items in the required atmosphere.",
            "title7": "Customer service",
            "desc7": "Our customer service team is always available and more than happy to receive your call and help. So, do not hesitate to contact our wonderful team anytime.",
            "title8": "Mechanical appliances",
            "desc8": "We use different machinery to handle all types of products. We have the right machine to handle large or small loads."
        }
    },
    "news": {
        "title": "Discover Logexa’s News",
        "description": "Discover more news, events, and what our Logexians get up to!"
    },
    "serviesInner": {
        "transportation": {
            "title1": "Cost-Effective",
            "desc1": "Since logistics firms don't have to invest in their own vehicles and drivers, offering transportation services may be a more affordable option. Additionally, outsourcing transportation can assist cut expenses for maintenance, gasoline, and insurance.",
            "title2": "Time-Saving",
            "desc2": "By offering transportation services, logistical organizations may free up their time from having to plan and oversee their own transportation logistics. They will be able to concentrate on their main business activities as a result, which will increase productivity.",
            "title3": "Scalability",
            "desc3": "Offering transportation services might make it easier for logistics businesses to grow their operations. Depending on their business requirements, businesses may quickly change the quantity of transportation services they need, such as adding or removing cars or drivers.",
            "title4": "Improved Customer Service",
            "desc4": "Providing transportation services may enhance customer service since logistics businesses can provide their clients with real-time tracking, more flexible delivery options, and quicker delivery timeframes. "
        }
    },
    "contactUsPage": {
        "title": "24/7",
        "titleLine2": "Customer service",
        "email": "info@logexa.com",
        "location": "Jeddah, KSA"
    },
    "serviceProvider": {
        "hero": {
            "heading": "Join Logexa’s Family ",
            "description": "Become a Logexa partner and earn extra income."
        },
        "ourMessage": {
            "title": "Our Message",
            "heading": "Do you have extra space in your warehouse and don’t know how to utilize it?",
            "desc": "We’ve got it all covered as Logexa is interested in becoming your partner. Efficiently, we help warehouses use their underutilized capacity to generate money without the hassle of dealing with multiple clients at once. Ultimately being a part of our network of service providers allows our partners to turn their cost- centers into profit- centers."
        },
        "step1": "Fill in our form and submit it to the platform.",
        "step2": "We will share the SLA and contract for you to sign.",
        "step3": "We will conduct an audit to ensure your warehouse meets our requirements.",
        "benefit": {
            "maindesc": "Benefits of Partnering With Us ",
            "mainTitle": "Why to join us as a service provider? ",
            "1": "Make more money without a sales or marketing team.",
            "2": "You won't need your sales or marketing teams to create your revenue as Logexa will do that on your behalf by filling up your warehouse",
            "3": "Since you will be working directly with Logexa, your logistics such as billing, tracking, and invoicing will be simplified by us.",
            "4": "We serve a diverse base of companies and clients across the kingdom; and without having to manage them individually, our partners enjoy doing business with them.",
            "5": "Headache free growth",
            "6": "Gain new clients with no extra work"
        },
        "contactUs": {
            "title": "It’s time to start",
            "desc": "Want to become a part of our successes and a partner in our progress !"
        }
    },
    how_it_works: {
        hero: {
            heading: "Warehousing & logistics made simple",
            description:
                "Our digital platform provides you with all the flexibility you need to conduct all your warehousing activities with a few simple steps.",
        },
        process: {
            title: "That’s why there is always room for more ",
            description: "The process of storing ",
            process1: {
                title: "Smooth",
                description:
                    "Immediate connection to warehouse providers allowing visibility & control to deliver to your client expectations",
            },
            process2: {
                title: "Steady",
                description:
                    "Provide you with immediate access to logistic solutions and tools.",
            },
            process3: {
                title: "Organized",
                description:
                    "Our warehouse system and loyal customer support provide you with a customized operational flow. ",
            },
        },
        steps: {
            title: "HOW IT WORKS?",
            description: "Storing your products couldn't be easier.",
            step1: {
                title: "Reserve a place",
                description:
                    "Following your registration on our platform, fill in simple details such as your product type, date of storage, and suitable storage temperature to complete the quotation process and reserve a space within our warehouses. ",
            },
            step2: {
                title: "Identify your products",
                description:
                    "Navigate to Product SKU to add your products. In this stage, you need to enter data about the products that will store with us, either manually or by uploading the required files.  ",
            },
            step3: {
                title: "Ship and pick your products",
                description:
                    "Following your registration on our platform, fill in simple details such as your product type, date of storage, and suitable storage temperature to complete the quotation process and reserve a space within our warehouses.  ",
            },

            step4: {
                title: "Pick your products",
                description:
                    "Pick whatever products you need, whenever you need them through a few clicks on our innovative digital platform for an easy hassle-free journey back to you. ",
            },
        },
        storage_options: {
            title: "Storage Types",
            AskQoutation:"Ask Quotation",
            description:
                "At Logexa, we provide temperature-controlled warehousing solutions for a variety of products. We curate the most suitable environment for your products with a wide range of temperature options available.",
            option1: {
                name: "Dry",
                description:
                    "Our dry storage facilities provide the most suitable environment for products that must be kept away from moisture and direct sunlight.",
            },
            option2: {
                name: "Ambient",
                degree: "21-25 degrees celsius",
                description:
                    "Ambient storage refers to a type of storage system in which products are typically kept at room temperature.A variety of foods, cosmetics, and chemicals are kept within our ambient storage facilities to preserve their quality and maintain their freshness.",
            },
            option3: {
                name: "Chilled",
                degree: "5-15 degrees celsius",
                description:
                    " A low temperature between 5-15 degrees Cْ is maintained for the items, thanks to our chilled storage warehouses where a variety of products can be stored to preserve their qualities.",
            },
            option4: {
                name: "Frozen",
                degree: "-18 degrees celsius",
                description:
                    "If you want to preserve your products’ quality and keep their freshness for long periods of time, then choose our frozen storage facilities where we keep your products stored at - 18 Celsius.This is the perfect storage solution for raw meat, poultry and some pharmaceuticals.",
            },
        },
        contact_us: {
            description:
                "Want to become a part of our success and a partner in our progress?",
            cta: "Contact us",
        },
    },
    blog: {
        title: "DISCOVER ARTICLES FROM LOGEXA",
        description: "Read and learn all about logistics, warehousing, and more!",
        description2:
            "At logexa, we are continuously working to gain knowledge and strive to shed light on useful information for our community to facilitate growth and spread awareness about relevant issues and topics.",
        cta: "Show More",
        article: {
            related: "RELATED ARTICLES",
            share_cta: "Share Article",
            more_articles: "More Articles",
            share_title: "Share Article",
            copied: "Copied",
            by: "By",
        },
    },

    
}
